import { createSelector } from '@reduxjs/toolkit';

import { InvestorSubTypes, UserTypes } from 'constants/user';
import { convertIntegrationsDataToList } from 'utils';

import { RootState } from '../store';

const selectState = (x: RootState) => x.currentUserReducer;

export const selectIsAuth = createSelector(selectState, (state) => state.isAuth);

export const selectUser = createSelector(selectState, (state) => state.data);
export const selectUserEntityData = createSelector(selectState, (state) => {
  const isTypeEntity = state.data?.type === InvestorSubTypes.ENTITY;
  const entityName = state.data?.entityCompany?.companyName ?? '';

  return { isTypeEntity, entityName };
});

export const selectUserAllowedTypes = createSelector(selectState, (state) => state.allowedUserTypes);

export const selectEntityInvestors = createSelector(selectState, (state) => state.entityInvestors);

export const selectSelfId = createSelector(selectState, (state) => state.data?.id);

export const selectCurrentUserIntegrationsList = createSelector(selectState, (state) =>
  convertIntegrationsDataToList(state.data?.linkedinIntegrations),
);

export const selectIsActionLoading = createSelector(selectState, (state) => state.isActionLoading);

export const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

export const selectUserType = createSelector(selectState, (state) => state.data?.userType);

export const selectMyInvites = createSelector(selectState, (state) => state.myInvites);

export const selectIsAdmin = createSelector(selectState, (state) => state.data?.userType === UserTypes.ADMIN);
