import React, { FC, useMemo } from 'react';

import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as SignOutSvg } from 'assets/svg/arrow-left.svg';
import { ReactComponent as SettingsSvg } from 'assets/svg/settings.svg';
import { ReactComponent as SwitchSvg } from 'assets/svg/switch.svg';
import { ROUTES } from 'constants/routes';
import { ButtonVariants } from 'constants/shared/button';
import { UserTypes } from 'constants/user';
import { selectUser, selectUserEntityData } from 'modules/current-user/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Avatar, ButtonDropdown } from 'shared-components';
import { getUserFullName } from 'utils';

import { updateUserType } from '../../modules/current-user/action';

interface Props {
  handleOpenSignOutModal: VoidFunction;
}

const UserDropdown: FC<Props> = ({ handleOpenSignOutModal }) => {
  const navigate = useNavigate();

  const { firstName, lastName, userType, entityCompany, availableTypes } = useAppSelector(selectUser) || {};
  const { isTypeEntity } = useAppSelector(selectUserEntityData);

  const dispatch = useAppDispatch();

  const userTypeShouldBeAdded = !!(availableTypes?.isFounder && availableTypes?.isInvestor);

  const fullUserName = getUserFullName({ firstName, lastName, userType, userTypeShouldBeAdded });

  const isShortName = (`${firstName} ${lastName}` || '')?.length < 10;

  const actions = useMemo(
    () => [
      ...(availableTypes?.isFounder && availableTypes?.isInvestor
        ? [
            {
              id: 'switchToAnotherUserType',
              title: `Switch to ${userType === UserTypes.INVESTOR ? 'Founder' : 'Investor'}`,
              icon: SwitchSvg,
              action: () =>
                dispatch(
                  updateUserType({ type: userType === UserTypes.INVESTOR ? UserTypes.FOUNDER : UserTypes.INVESTOR }),
                ),
            },
          ]
        : []),
      ...(userType !== UserTypes.ADMIN
        ? [{ id: 'settings', title: 'Settings', icon: SettingsSvg, action: () => navigate(ROUTES.settings) }]
        : []),

      { id: 'signOut', title: 'Sign Out', icon: SignOutSvg, action: handleOpenSignOutModal },
    ],
    [handleOpenSignOutModal, navigate, userType],
  );

  return (
    <div className='flex items-center cursor-pointer relative'>
      <Avatar alt='user-avatar' />
      <ButtonDropdown
        className='w-auto pl-0 h-auto text-grey-800 bg-white whitespace-nowrap mx-2 border-none rounded-none [&_path]:fill-grey-800'
        menuClassName={twMerge(cn('z-50 min-w-[190px] pt-[0px] !-left-8', { '!left-8': !isShortName }))}
        buttonText={isTypeEntity ? entityCompany?.companyName : fullUserName}
        actions={actions}
        additionalTopValue={45}
        variant={ButtonVariants.TRANSPARENT}
      />
    </div>
  );
};

export default UserDropdown;
