import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { currentUserApi, currentUserProfileApi, investorApi } from 'apis';
import { LocalStorageKeys } from 'constants/global';
import { ROUTES } from 'constants/routes';
import { UserTypes } from 'constants/user';
import { showServerError, successNotify } from 'helpers';
import {
  CurrentUser,
  UserIntegration,
  ProfileSettingsInput,
  CurrentUserState,
  MyInvitesPayload,
  GenerateDueDiligenceInviteFounderLinkPayload,
  CompanyEntityInput,
  GetListPayload,
  UpdateUserType,
  AlreadyRegisteredTypes,
  InvestorSigningPayload,
} from 'interfaces';
import history from 'services/history';
import { getAccessToken, resetUserStorage, navigateByRole, checkIsAdminOrFmUser } from 'utils';

export const CURRENT_USER_SLICE_NAME = 'currentUser';

export const getUser = createAsyncThunk(`${CURRENT_USER_SLICE_NAME}/getUser`, async () => {
  const {
    data: { data },
  } = await currentUserApi.getUserProfile();

  if (data?.availableTypes) {
    setCurrentUserAllowedTypes(data.availableTypes);
  }

  const isMultiSignIn = localStorage.getItem(LocalStorageKeys.IS_MULTI_SIGN_IN);
  const isMultiFLow = data?.availableTypes?.isFounder && data?.availableTypes?.isInvestor && isMultiSignIn !== '0';

  localStorage.setItem(LocalStorageKeys.IS_MULTI_SIGN_IN, isMultiFLow ? '1' : '0');

  return { data, isAuth: !!getAccessToken() };
});

export const logoutUser = createAsyncThunk(`${CURRENT_USER_SLICE_NAME}/logoutUser`, async () => {
  const response = await currentUserApi.userLogOut();

  history.push(navigateByRole(checkIsAdminOrFmUser(), ROUTES.signIn));
  resetUserStorage();
  return response?.data;
});

export const getUserInfoByHash = createAsyncThunk(
  `${CURRENT_USER_SLICE_NAME}/getUserInfoByHash`,
  async (hash: string, { rejectWithValue }) => {
    try {
      const response = await currentUserApi.getUserInfoByHash(hash);
      localStorage.setItem(LocalStorageKeys.AUTH_ACCESS_TOKEN, response?.data.accessToken);

      return response.data.data;
    } catch (e) {
      history.push(ROUTES.signIn);
      return rejectWithValue(e);
    }
  },
);

export const getEntityInvestors = createAsyncThunk(
  `${CURRENT_USER_SLICE_NAME}/getEntityInvestors`,
  async (payload: GetListPayload & { id: number }, { rejectWithValue }) => {
    try {
      const response = await investorApi.getInvestorEntities(payload);

      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const updateLinkedInIntegration = createAsyncThunk(
  `${CURRENT_USER_SLICE_NAME}/updateLinkedInIntegration`,
  async (data: UserIntegration, { rejectWithValue }) => {
    try {
      const response = await currentUserProfileApi.updateLinkedInIntegration(data);

      successNotify('LinkedIn integrations was successfully updated');
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);
export const updateUserType = createAsyncThunk(
  `${CURRENT_USER_SLICE_NAME}/updateUserType`,
  async (data: UpdateUserType, { rejectWithValue }) => {
    try {
      const response = await currentUserProfileApi.updateUserType(data);

      localStorage.setItem(LocalStorageKeys.USER_TYPE, response.data?.data?.userType);
      successNotify('User type was successfully updated');
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const deleteUserInformation = createAsyncThunk(
  `${CURRENT_USER_SLICE_NAME}/updateLinkedInIntegration`,
  async () => {
    const response = await currentUserProfileApi.deleteUserInformation();

    return response.data;
  },
);

export const updateCurrentUser = createAsyncThunk(
  `${CURRENT_USER_SLICE_NAME}/updateCurrentUser`,
  async (data: ProfileSettingsInput, { rejectWithValue }) => {
    try {
      const { email, phone, firstName, lastName } = data;

      const response = await currentUserProfileApi.updateUser({ email, phone, firstName, lastName });

      successNotify('Profile was successfully updated');
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const updateEntity = createAsyncThunk(
  `${CURRENT_USER_SLICE_NAME}/updateEntity`,
  async (data: CompanyEntityInput, { rejectWithValue }) => {
    try {
      const { companyName, companyNumber, ...restData } = data;

      const response = await currentUserProfileApi.updateEntity({
        companyName: companyName?.value ?? '',
        companyNumber: companyNumber?.value ?? '',
        ...restData,
      });

      successNotify('Entity was successfully updated');
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const inviteUser = createAsyncThunk(
  `${CURRENT_USER_SLICE_NAME}/inviteNewUser`,
  async ({ userType, type, inviteType, ...data }: MyInvitesPayload, { rejectWithValue }) => {
    try {
      const response = await currentUserApi.inviteUser({
        ...data,
        type: (type ? type : userType?.value || '') as UserTypes,
        inviteType: inviteType || null,
      });

      successNotify('The invitation email has been sent');

      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const resendUserInvite = createAsyncThunk(
  `${CURRENT_USER_SLICE_NAME}/resendUserInvite`,
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await currentUserApi.resendUserInvite(id);

      successNotify('The invitation email has been sent');

      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const getInviteLinkByCompanyId = createAsyncThunk(
  `${CURRENT_USER_SLICE_NAME}/getInviteLinkByCompanyId`,
  async (companyId: number | string, { rejectWithValue }) => {
    try {
      const response = await currentUserApi.getInviteLinkByCompanyId(Number(companyId));

      return response.data?.data?.inviteLink;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const getMyInvites = createAsyncThunk(`${CURRENT_USER_SLICE_NAME}/getMyInvites`, async () => {
  const response = await currentUserApi.getInvites();

  return response.data.data;
});

export const deletePendingInviteUser = createAsyncThunk(
  `${CURRENT_USER_SLICE_NAME}/deletePendingInviteUser`,
  async (id: number, { rejectWithValue }) => {
    try {
      await currentUserApi.deletePendingInvite(id);

      successNotify('The pending invite was successfully removed');

      return id;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const generateDueDiligenceInviteFounderLink = createAsyncThunk(
  `${CURRENT_USER_SLICE_NAME}/generateInviteFounderLink`,
  async (payload: GenerateDueDiligenceInviteFounderLinkPayload, { rejectWithValue }) => {
    try {
      const response = await currentUserApi.generateDueDiligenceInviteFounderLink(payload);

      return response.data.data.inviteLink;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const checkIsUserAlreadyRegistered = createAsyncThunk(
  `${CURRENT_USER_SLICE_NAME}/checkIsUserAlreadyRegistered`,
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await currentUserApi.checkIsAlreadyRegisteredByEmail(email);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateSigningAuthority = createAsyncThunk(
  `${CURRENT_USER_SLICE_NAME}/updateSigningAuthority`,
  async (payload: InvestorSigningPayload & { id: number }, { rejectWithValue }) => {
    try {
      const response = await investorApi.updateSigningAuthority(payload);
      return response.data.data;
    } catch (err) {
      showServerError(err);
      return rejectWithValue(err);
    }
  },
);

export const setCurrentUser = createAction<CurrentUser>('SET_CURRENT_USER');

export const setCurrentUserAllowedTypes = createAction<AlreadyRegisteredTypes>('SET_CURRENT_USER_ALLOWED_TYPES');

export const setCurrentUserState = createAction<CurrentUserState>('SET_CURRENT_USER_STATE');
