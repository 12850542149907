/* eslint-disable no-unused-vars */

import { FormItem } from 'interfaces';
import { Input } from 'shared-components';

import { FieldTypes } from './shared';

export enum SignInFieldNames {
  EMAIL = 'email',
  PASSWORD = 'password',
  REMEMBER_ME = 'remember_me',
}

export enum SIGN_IN_FLOWS {
  CREATE_SHARE_EXCHANGE = 'cse',
}

export enum MULTI_SIGN_IN_FLOWS {
  SIGN_IN = 'sign_in',
}

export const SIGN_IN_PAGE_TITLE = 'Sign in';

export const SIGN_IN_PAGE_ADDITIONAL_TEXT = 'Not a member?';

export const SIGN_IN_PAGE_LINK_TEXT = 'Register now';

export const SIGN_EMAIL_ADDRESS_DATA_TEST_ID = 'SIGN_EMAIL_ADDRESS_DATA_TEST_ID';
export const SIGN_PASSWORD_DATA_TEST_ID = 'SIGN_PASSWORD_DATA_TEST_ID';
export const LINK_FORGET_PASSWORD_DATA_TEST_ID = 'LINK_FORGET_PASSWORD_DATA_TEST_ID';
export const LOGIN_BUTTON_DATA_TEST_ID = 'LOGIN_BUTTON_DATA_TEST_ID';

export const SIGN_IN_FIELDS: FormItem[] = [
  {
    name: SignInFieldNames.EMAIL,
    label: 'Email Address',
    type: FieldTypes.TEXT,
    required: true,
    component: Input,
    validation: {
      required: 'Required information missing please input Email address to continue',
      pattern: { message: 'Invalid email address', value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
    },
    className: 'mb-6',
    dataTestId: SIGN_EMAIL_ADDRESS_DATA_TEST_ID,
  },
  {
    name: SignInFieldNames.PASSWORD,
    label: 'Password',
    type: FieldTypes.PASSWORD,
    required: true,
    component: Input,
    validation: {
      required: 'Required information missing please input Password to continue',
    },
    className: 'mb-6',
    dataTestId: SIGN_PASSWORD_DATA_TEST_ID,
  },
];
