import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import {
  AlreadyRegisteredTypes,
  CompanyDataMainData,
  CurrentUser,
  CurrentUserState,
  InvestorEntitiesResponse,
  InvitedUser,
} from 'interfaces';

import {
  updateLinkedInIntegration,
  getUser,
  getUserInfoByHash,
  setCurrentUser,
  logoutUser,
  updateCurrentUser,
  setCurrentUserState,
  inviteUser,
  getMyInvites,
  deletePendingInviteUser,
  updateEntity,
  getEntityInvestors,
  setCurrentUserAllowedTypes,
  updateUserType,
  updateSigningAuthority,
} from './action';
import { initialState } from './slice';

export function getUserReducer(builder: ActionReducerMapBuilder<CurrentUserState>) {
  builder.addCase(getUser.pending, (state) => {
    state.isActionLoading = true;
  });
  builder.addCase(getUser.fulfilled, (state, action: PayloadAction<{ data: CurrentUser; isAuth: boolean }>) => {
    state.data = action.payload.data;
    state.isAuth = action.payload.isAuth;
    state.isActionLoading = false;
  });
  builder.addCase(getUser.rejected, (state) => {
    state.isAuth = false;
    state.isActionLoading = false;
  });
}

export function updateUserTypeReducer(builder: ActionReducerMapBuilder<CurrentUserState>) {
  builder.addCase(updateUserType.pending, (state) => {
    state.isActionLoading = true;
  });
  builder.addCase(updateUserType.fulfilled, (state, action: PayloadAction<CurrentUser>) => {
    state.data = action.payload;
    state.isActionLoading = false;
  });
  builder.addCase(updateUserType.rejected, (state) => {
    state.isActionLoading = false;
  });
}

export function logoutUserReducer(builder: ActionReducerMapBuilder<CurrentUserState>) {
  builder.addCase(logoutUser.pending, (state) => {
    state.isActionLoading = true;
  });
  builder.addCase(logoutUser.fulfilled, () => initialState);
  builder.addCase(logoutUser.rejected, (state) => {
    state.isActionLoading = false;
  });
}

export function setCurrentUserReducer(builder: ActionReducerMapBuilder<CurrentUserState>) {
  builder.addCase(setCurrentUser, (state, action: PayloadAction<CurrentUser>) => {
    state.data = action.payload;
    state.isAuth = true;
  });
}

export function setCurrentUserAllowedTypesReducer(builder: ActionReducerMapBuilder<CurrentUserState>) {
  builder.addCase(setCurrentUserAllowedTypes, (state, action: PayloadAction<AlreadyRegisteredTypes>) => {
    state.allowedUserTypes = action.payload;
  });
}

export function getUserInfoByHashReducer(builder: ActionReducerMapBuilder<CurrentUserState>) {
  builder.addCase(getUserInfoByHash.fulfilled, (state, action: PayloadAction<CurrentUser>) => {
    state.data = action.payload;
  });
}

export function updateLinkedInIntegrationReducer(builder: ActionReducerMapBuilder<CurrentUserState>) {
  builder.addCase(updateLinkedInIntegration.pending, (state) => {
    state.isActionLoading = true;
  });

  builder.addCase(updateLinkedInIntegration.fulfilled, (state, action: PayloadAction<CurrentUser>) => {
    state.isActionLoading = false;
    state.data = action.payload;
  });

  builder.addCase(updateLinkedInIntegration.rejected, (state) => {
    state.isActionLoading = false;
  });
}

export function updateCurrentUserReducer(builder: ActionReducerMapBuilder<CurrentUserState>) {
  builder.addCase(updateCurrentUser.pending, (state) => {
    state.isActionLoading = true;
  });

  builder.addCase(updateCurrentUser.fulfilled, (state, action: PayloadAction<CurrentUser>) => {
    state.isActionLoading = false;
    state.data = action.payload;
  });

  builder.addCase(updateCurrentUser.rejected, (state) => {
    state.isActionLoading = false;
  });
}

export function updateEntityReducer(builder: ActionReducerMapBuilder<CurrentUserState>) {
  builder.addCase(updateEntity.pending, (state) => {
    state.isActionLoading = true;
  });

  builder.addCase(updateEntity.fulfilled, (state, action: PayloadAction<CompanyDataMainData & { id: number }>) => {
    state.isActionLoading = false;

    if (state.data) {
      state.data.entityCompany = action.payload;
    }
  });

  builder.addCase(updateEntity.rejected, (state) => {
    state.isActionLoading = false;
  });
}

export function inviteUserReducer(builder: ActionReducerMapBuilder<CurrentUserState>) {
  builder.addCase(inviteUser.pending, (state) => {
    state.isActionLoading = true;
  });

  builder.addCase(inviteUser.fulfilled, (state, action: PayloadAction<InvitedUser>) => {
    state.isActionLoading = false;
    state.myInvites.unshift(action.payload);
  });

  builder.addCase(inviteUser.rejected, (state) => {
    state.isActionLoading = false;
  });
}

export function getMyInvitesReducer(builder: ActionReducerMapBuilder<CurrentUserState>) {
  builder.addCase(getMyInvites.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(getMyInvites.fulfilled, (state, action: PayloadAction<InvitedUser[]>) => {
    state.isLoading = false;
    state.myInvites = action.payload;
  });

  builder.addCase(getMyInvites.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getEntityInvestorsReducer(builder: ActionReducerMapBuilder<CurrentUserState>) {
  builder.addCase(getEntityInvestors.pending, (state) => {
    state.entityInvestors.isLoading = true;
  });

  builder.addCase(getEntityInvestors.fulfilled, (state, action: PayloadAction<InvestorEntitiesResponse>) => {
    state.entityInvestors.isLoading = false;
    state.entityInvestors.data = action.payload.data;
    state.entityInvestors.total = action.payload.meta.total;
    state.entityInvestors.totalPages = action.payload.meta.last_page;
  });

  builder.addCase(getEntityInvestors.rejected, (state) => {
    state.entityInvestors.isLoading = false;
  });
}

export function deletePendingInviteReducer(builder: ActionReducerMapBuilder<CurrentUserState>) {
  builder.addCase(deletePendingInviteUser.pending, (state) => {
    state.isActionLoading = true;
  });

  builder.addCase(deletePendingInviteUser.fulfilled, (state, action: PayloadAction<number>) => {
    state.isActionLoading = false;
    state.myInvites = state?.myInvites?.filter(({ id }) => id !== action.payload);
  });

  builder.addCase(deletePendingInviteUser.rejected, (state) => {
    state.isActionLoading = false;
  });
}

export function setCurrentUserStateReducer(builder: ActionReducerMapBuilder<CurrentUserState>) {
  builder.addCase(setCurrentUserState, (state, action: PayloadAction<CurrentUserState>) => action.payload);
}

export function updateSigningAuthorityReducer(builder: ActionReducerMapBuilder<CurrentUserState>) {
  builder.addCase(updateSigningAuthority.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(updateSigningAuthority.fulfilled, (state, action: PayloadAction<CurrentUser>) => {
    state.isLoading = false;
    state.data = action.payload;
  });

  builder.addCase(updateSigningAuthority.rejected, (state) => {
    state.isLoading = false;
  });
}
