import { TableColumns } from '../interfaces';

export const INVESTORS_PAGE_TITLE = 'Investors';

export const INVESTMENTS_PAGE_PLACEHOLDER = 'Search by First Name, Last Name, Email Address or Phone';

export const INVESTORS_TABLE_COLUMNS: TableColumns[] = [
  { id: 'actions', title: 'Actions' },
  { id: 'firstName', title: 'First Name', sortable: true },
  { id: 'lastName', title: 'Last Name', sortable: true },
  { id: 'email', title: 'Email address', sortable: true },
  { id: 'phone', title: 'Phone number', sortable: true },
];

export const INVESTORS_DONE_SHARE_EXCHANGES_TABLE_COLUMNS: TableColumns[] = [
  { id: 'firstName', title: 'First Name' },
  { id: 'lastName', title: 'Last Name' },
  { id: 'noDoneSEs', title: 'No. of Done SE' },
];

export const ADMIN_DASHBOARD_INVESTORS_PIE_CHART_METRICS_LABELS = {
  totalRegistered: 'Registered Users',
  totalPending: 'Pending Investors',
  totalCompleted: 'Investors',
};

export enum INVESTOR_PAGE_TABS_IDS {
  INVESTMENTS = 'investments',
  DEALS = 'share-exchanges',
  INVOICES = 'invoices',
}

export enum InvestorProfileInfo {
  EMAIL_ADDRESS = 'Email Address',
  PHONE_NUMBER = 'Phone No',
  CREATED_AT = 'Created At',
  DAXIA_INVESTMENTS = 'Investment Company Daxia Limited',
}
